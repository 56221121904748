
@tailwind base;
@tailwind components;
@tailwind utilities;

/* @font-face {
  font-family: 'YourFontName';
  src: url('../assets/fonts/YourFontName-Bold.woff2') format('woff2'),
    url('../assets/fonts/YourFontName-Bold.woff') format('woff'),
    url('../assets/fonts/YourFontName-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
} */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

  body {
      /* background-color:#202020; */
      color:#202020;   
      background-color: black;
   }

   html, body {
    margin: 0;
    padding: 0;
    overflow: hidden; 
  }


   /* Optional: Remove any existing scrollbar styles */
    body::-webkit-scrollbar {
      display: none; /* For WebKit browsers */
    }


  @font-face {
    font-family: 'avenir'; /* Give the font a name */
    src: url('./assets/fonts/Avenir/Avenir Light/Avenir\ Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

