

.dhonicontainer{
    width: 99.8vw;
    height: 99.8vh;
    position: relative;
    background-color: black;
}

.gradient{
    position: absolute;
    top:0;
    left:0;
    z-index: 10;
    /* width: 100%;
    height: 100%; */

}

.singledhoni{
    position: absolute;
    bottom: 10px;
    left:0;
    z-index: 999;
    width: 100%;
}