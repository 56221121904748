/* Add this to your CSS file or in a style tag in your component */

.mainWrapper{
  width: 24%;
  margin: auto;
  color: white;
/* background-color: yellow; */
/* height: 100vh; */
}


.play-button {
  transition: opacity 0.3s ease;
  opacity: 1;
  cursor: pointer;
}

.video-container:hover .play-button {
  opacity: 1;
}

.video-container {
  position: relative;
  text-align: center;
  max-width: 24%;
  width: auto; 
  height: 99.8vh; 
  overflow: hidden; 
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  background-color: black;
  position: absolute;
  top: 49.9%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-container video{
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  object-fit: contain !important; 
}


.video-container .play-button.hidden {
  opacity: 0;
}

/* Welcome page css styling */

.container{
  position: relative;
  width: 100%;
  /* height: 100%; */
}

.container> img{
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  object-fit: fill; /* Fills the screen while preserving aspect ratio, may crop the image */
  transform: translate(-50%, -50%);

}

.contentSection{
    position: absolute;
    top: 1rem; /* Positions the content 1rem from the top */
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 28%; /* Occupies 1/4 of the screen height */
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
}


.upperSection{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 0.8rem;
}

.upperSection img{
  width: 6.5rem;
}

.primaryText{
  font-size: 0.87rem;
  margin: auto;
  display: block;
}

.heading{
font-size: 21px;
font-weight: 600;
}

#viewmessagebtn {
  width: 10rem;
  position: absolute;
  bottom: 4rem;
  left: 5%;
  transform: translateX(-50%);
  padding: 10px;
  border-radius: 5px;
  border: none;
  background: linear-gradient(180deg, #dbdbdb 0%, #656565 100%);
  letter-spacing: 0.6px;
  opacity: 0;
  animation: slideUp 1s ease-out forwards 1s;
  color: black;
  font-size: 16px;
  cursor: pointer;
  font-weight: 600;
}

.ConclusionContainer{
  height: 100vh;
  background-size: cover;
  background-position-y: center;
 
  width: 110%;
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


/* Galaxy S8 */
@media screen and (min-width: 320px) and (max-width: 376px) {

  .contentSection {
    top:0.7rem;
    height: 30%;
  }

  .upperSection {
    gap: 0.5rem;
  }

  #viewmessagebtn {
       bottom:1.8rem;
  }
  .mainWrapper{
    width: 100%;
  }

}

/*iPhone 7 / 13 mini */
@media screen and (min-width: 365px) and (max-width: 385px) {

        .upperSection img{
          width: 5.8rem;
      }

      .heading{
        font-size: 1.2rem;
      }
    
      .primaryText{
        font-size: 0.9rem;
      }

        #viewmessagebtn {
          bottom:1rem;
      }

}

/* Working perfectly for iPhone 12*/
@media screen and (min-width: 386px) and (max-width: 450px) {
  /* .mainWrapper{
    width: 100%;
  } */
  .contentSection {
    top:0.5rem;
    height: 25%;
  }

  .upperSection img{
     width: 6rem;
  }

  .heading{
    font-size: 1.3rem;
  }

  .primaryText{
    font-size: 1rem;
  }
  
  #viewmessagebtn {
     bottom:5rem;
  }

}






/* Moto G34 */
@media screen and (min-width: 426px) and (max-width:539px) {


      #viewmessagebtn {
        bottom:3rem;
     }
   
}

@media screen and (min-width: 540px) and (max-width:600px) {

      #viewmessagebtn {
        bottom:3rem;
    }
   
}

@media screen and (min-width:600) {
  .mainWrapper{
    width: 100%;
  }
  
}
/* tabs */
@media screen and (min-width: 601px) and (max-width: 768px) {
          #viewmessagebtn {
            bottom: 4rem;
        }
}


/* laptops */
@media screen and (min-width: 769px) {

    .video-container {
      width: auto;
    }

    .upperSection img{
      width: 5rem;
     }

    .heading{
      font-size: 1rem;
      font-weight: 600;
      }

      .primaryText{
        font-size: 0.8rem;
        margin: auto;
        display: block;
      }

      #viewmessagebtn {
        bottom:0.7rem;
     }
 
}

/* @media screen and (max-height: 540px) and (min-height: 500px) {
  .container img{
    border: 1px solid greenyellow;
  }

} */


@media (max-width: 768px) {
  .mainWrapper {
    width: 100% !important;
  }
  #popupLayout{
  background-color: rgba(0, 0, 0, 0.4)
  }
  .video-container {
    width: 100%;
    max-width: 100%;
  }
  body{
          background-color:#202020;

  }
}