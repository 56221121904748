.container{
    background-color: black;
    width: 99.9vw;
    max-height: 99.8vh;
    height: 99.8vh;
    /* border: 1px solid white; */
    display: flex;
    /* align-items: center; */
    justify-content: center;
}
.innerContainer{
    /* border: 1px solid yellow; */
    /* background-color: yellow; */
    width: 100%;
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 7%;
}
.innerContainer img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.vector{
    height: 80vh;
    width: 100%;
    position: absolute;
    bottom: 0px;
}
.vector img{
    width: 100%;
    height: 100%;
    object-fit: fill;
}
.circle{
    position: absolute;
    width: 100%;
    height: 70%;
    background: radial-gradient(circle, #ffffff37 10%, transparent 90%);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid black;
    top: 25%;
    


}
.contentSectionNew{
    /* border: 1px solid red; */
    position: absolute;
    bottom: 3%;
    width: 99.8%;
    display: flex;
    flex-direction: column;
    gap: 5%;
    align-items: center;
    justify-content: space-between;
    color: white;
    height: 95vh;
    padding-bottom: 4rem;
    text-align: center;

}
.contentSectionNew div{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.contentSectionNew img{
    width: 40%;
}
.primaryText{
    font-size: 1.5rem;
    font-weight: 600;
}
.secondaryText{
    font-size: 0.8rem;
}
.contentSectionNew button{
    width: 80%;
    padding: 10px;
    border-radius: 10px;
    color: black;
    font-size: 18px;
    font-weight: 600;
    background: linear-gradient(180deg, #DBDBDB 0%, #656565 100%);
}
@media screen and (min-width: 426px) and (max-width:539px) {
    .primaryText{
        font-size: 1.8rem;
    }
    .secondaryText{
        font-size: 1.1rem;
    }


}
@media screen and (min-width: 769px) {

    .container,  .vector{
        width: 20rem;
    }
    .innerContainer{
        width: 70%;
        bottom: 0px;
        max-height: 70%;
    }
    .innerContainer img{
        max-width: 100%;
        max-height: 100%;
    }
    .contentSectionNew{
        width: 19.9rem;
        padding-bottom: 0px;

    }
    .upperSection{
        /* min-height: 14.5rem;
        max-height: 14.5rem; */
    }
    .primaryText{
        font-size: 1.2rem;
    }
    .secondaryText{
        font-size: 0.8rem;
    }

}